.root {
  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #cccccc;
  }
}
