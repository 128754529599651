@import "variables";
@import "mixins";
@import "animations";

.root {
  position: relative;
  display: block;

  .btn-filter {
    display: flex;
    justify-content: space-between;
    outline: none;
  }

  .popup {
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    top: 100%;
    align-items: center;
    position: absolute;
    //min-width: 300px;
    //max-height: 400px;
    //overflow-y: scroll;
    z-index: 1;
    @include box-shadow(2px, 4px, 5px, rgba(0, 0, 0, 0.1), false);
    @include round-bottom-corners(5px);
    @include animation("fadeIn 0.3s");

    .checkbox {
      position: relative;
      width: 1.2em;
      height: 1.2em;
      color: $black;
      border: 1px solid $gray;
      border-radius: 4px;
      appearance: none;
      outline: 0;
      cursor: pointer;
      transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

      &::before {
        position: absolute;
        content: "";
        display: block;
        top: 1px;
        left: 5px;
        width: 5px;
        height: 12px;
        border-style: solid;
        border-color: $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
      }

      &:checked {
        color: $white;
        border-color: $mystery-grey;
        background: $mystery-grey;

        &::before {
          opacity: 1;
        }

        ~ label::before {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
      }
    }
  }

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #cccccc;
  }
}

.checkbox {
  position: relative;
  width: 1.2em;
  height: 1.2em;
  color: $black;
  border: 1px solid $gray;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

  &::before {
    position: absolute;
    content: "";
    display: block;
    left: 5px;
    width: 5px;
    height: 12px;
    border-style: solid;
    border-color: $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }

  &:checked {
    color: $white;
    border-color: $mystery-grey;
    background: $mystery-grey;

    &::before {
      opacity: 1;
    }

    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

.scroll {
  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #cccccc;
  }
}
